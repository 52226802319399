<template>
  <div>
    <div class="title-sm">파트너 정보(내부 관리용)</div>
    <b-row class="mb-2">
      <b-col>
        <small>홈페이지</small><br/>
        <b-input-group>
          <b-form-input v-model="item.homepage"></b-form-input>
          <b-input-group-append v-if="item.homepage && item.homepage.startsWith('http')">
            <b-button variant="primary" @click="$utils.open(item.homepage)"><i class="fa fa-external-link"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col md="6">
        <div class="clearfix">
          <div class="pull-right">
            <b-badge class="mr-1 pointer" :variant="item.addressType === 'global' ? 'light' : 'info'" @click="setAddressType('domestic')">국내</b-badge>
            <b-badge class="pointer" :variant="item.addressType === 'global' ? 'success' : 'light'" @click="setAddressType('global')">해외</b-badge>
          </div>
          <small>발송지 주소</small><br/>
        </div>
        <b-form-input v-model="item.address" readonly></b-form-input>

        <template v-if="item.addressType === 'global'">
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-input class="bg-white" v-model="item.addr.postcode" placeholder="Zip/Postal Code" @input="updateAddress()"></b-input>
            </b-input-group-prepend>
            <b-input class="bg-white" v-model="item.addr.address" placeholder="City, State/Province, Country" @input="updateAddress()"></b-input>
          </b-input-group>
          <b-input class="mt-1 mb-2" ref="addressDetail" v-model="item.addr.addressDetail" placeholder="Detail Address" @input="updateAddress()"></b-input>
        </template>
        <template v-else>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-input class="bg-white" v-model="item.addr.postcode" @click="openAddressPopup" placeholder="우편번호" readonly></b-input>
            </b-input-group-prepend>
            <b-input class="bg-white" v-model="item.addr.address" @click="openAddressPopup" placeholder="기본주소" readonly></b-input>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="openAddressPopup">주소 검색</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input class="mt-1 mb-2" ref="addressDetail" v-model="item.addr.addressDetail" placeholder="상세주소" @input="updateAddress()"></b-input>
        </template>
      </b-col>

      <b-col md="6" v-if="item.return_ok === 'y'">
        <div class="clearfix">
          <div class="pull-right">
            <b-badge class="mr-1 pointer" :variant="item.returnAddressType === 'global' ? 'light' : 'info'" @click="setReturnAddressType('domestic')">국내</b-badge>
            <b-badge class="pointer" :variant="item.returnAddressType === 'global' ? 'success' : 'light'" @click="setReturnAddressType('global')">해외</b-badge>
          </div>
          <small>반품지 주소</small><br/>
        </div>
        <b-form-input v-model="item.return_address" readonly></b-form-input>

        <template v-if="item.returnAddressType === 'global'">
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-input class="bg-white" v-model="item.addr.returnPostcode" placeholder="Zip/Postal Code" @input="updateAddress('return')"></b-input>
            </b-input-group-prepend>
            <b-input class="bg-white" v-model="item.addr.returnAddress" placeholder="City, State/Province, Country" @input="updateAddress('return')"></b-input>
          </b-input-group>
          <b-input class="mt-1 mb-2" ref="returnAddressDetail" v-model="item.addr.returnAddressDetail" placeholder="Detail Address" @input="updateAddress('return')"></b-input>
        </template>
        <template v-else>
          <b-input-group class="mt-2">
            <b-input-group-prepend>
              <b-input class="bg-white" v-model="item.addr.returnPostcode" @click="openAddressPopup('return')" placeholder="우편번호" readonly></b-input>
            </b-input-group-prepend>
            <b-input class="bg-white" v-model="item.addr.returnAddress" @click="openAddressPopup('return')" placeholder="기본주소" readonly></b-input>
            <b-input-group-append>
              <b-button variant="outline-primary" @click="openAddressPopup('return')">주소 검색</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input class="mt-1 mb-2" ref="returnAddressDetail" v-model="item.addr.returnAddressDetail" placeholder="상세주소" @input="updateAddress('return')" :disabled="false && item._partner"></b-input>
        </template>
      </b-col>
    </b-row>

    <div v-if="item._partner">
      <div>
        <div class="title-sm">사업자 정보</div>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>사업자번호</small><br/>
            <b-input v-model="item.biz_no"></b-input>
          </b-col>
          <b-col cols="6">
            <small>통신판매신고번호</small>
            <b-form-input v-model="item.biz_report_no"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">
            <small>사업자</small>
            <b-form-input v-model="item.biz_name"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>대표자</small><br/>
            <b-input v-model="item.biz_owner"></b-input>
          </b-col>
          <b-col cols="3">
            <small>업태</small><br/>
            <b-form-input v-model="item.biz_type"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>종목</small><br/>
            <b-form-input v-model="item.biz_item"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>사업장 대표 메일</small><br/>
            <b-input v-model="item.biz_email"></b-input>
          </b-col>
          <b-col cols="6">
            <small>사업장 소재지</small>
            <b-form-input v-model="item.biz_address"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>사업자 등록증 확인</small>
            <div class="col-form-label" v-if="item.bizFile && item.bizFile.bizName">
              <a v-if="PRIVATE" @click="$utils.open(`http://files.balaan.io/${item.bizFile.s3Biz}`)" :title="item.bizFile.bizName" class="pointer">
                <i class="fa fa-file-o"></i>
                {{ item.bizFile.bizName }}
              </a>
              <span v-else>{{ item.bizFile.bizName }}</span>
            </div>
          </b-col>
          <b-col cols="6">
            <small>통신판매신고증 확인</small>
            <div class="col-form-label" v-if="item.bizReportFile && item.bizReportFile.bizReportName">
              <a v-if="PRIVATE" @click="$utils.open(`http://files.balaan.io/${item.bizReportFile.s3BizReport}`)" :title="item.bizReportFile.bizReportName" class="pointer">
                <i class="fa fa-file-o"></i>
                {{ item.bizReportFile.bizReportName }}
              </a>
              <span v-else>{{ item.bizReportFile.bizReportName }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <div class="title-sm">정산 정보</div>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>정산 유무</small><br/>
            <b-input-group>
              <b-form-select v-model="item.settle_yn" :options="$C.OPTIONS.USE_YN" @change="checkSettlemYNChange"></b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <small>정산 유형</small>
            <i class="fa fa-question-circle ml-1" id="settle-guide-text"/>
            <b-tooltip target="settle-guide-text" custom-class="shop-settle-tooltip w-400px">
              파트너의 정산 유형을 설정할 수 있습니다.<br/>
              - 정산 대상
              <ul>
              <li>A Type: 매주 월~일요일까지 구매확정된 주문</li>
              <li>C Type: 매월 1일~15일, 16일~말일까지 구매확정된 주문</li>
              <li>E Type: 매월 1일~말일까지 구매확정된 주문</li>
              <li>G Type: 당일 00시~23시59분59초까지 구매확정된 주문</li>
              <li>Z Type: 당일 00시~23시59분59초까지 구매확정된 주문</li>
              <li style="list-style-type: '※ '">취소확인/반품완료 주문은 정산 대상에서 제외되고, 고객보상금액은 정상금액에서 차감 됩니다</li>
              </ul>
              <p/>
              - 자동 가마감일<br/>
              <ul>
                <li>A Type: 매주 월요일 00시</li>
                <li>C Type: 매월 16일 00시, 1일 00시</li>
                <li>E Type: 매월 1일 00시</li>
                <li>G Type: 매일 00시</li>
                <li>Z Type: 매일 00시</li>
              </ul>
              - 정산 예정일<br/>
              <ul>
                <li>A Type: 매주 정산 대상 종료일인 '일요일' 제외 +15영업일 당일</li>
                <li>C Type: 매월 정산 대상 종료일인 '15일', ‘말일' 제외 +15영업일 당일</li>
                <li>E Type: 매월 정산 대상 종료일인 ‘말일' 제외 +15영업일 당일</li>
                <li>G Type: 매일 정산 대상일인 ‘당일' 제외 + 1영업일 당일</li>
                <li>Z Type: 매일 정산 대상일인 ‘당일' 제외 + 1영업일 당일</li>
                <li style="list-style-type: '※ '">정산 예정일은 영업일 기준의 날짜만 지정됩니다. (월~금 / 공휴일 제외)</li>
              </ul>
            </b-tooltip>
            <br/>
            <b-input-group>
              <b-form-select v-model="item.settlement_type" :options="PARTNER_SETTLE_TYPE"></b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <small>계좌번호</small><br/>
            <b-input-group>
              <b-input-group-prepend>
                <b-form-select v-model="item.bank_code" :options="[
                                     {text:'은행선택', value:'', disabled: true},
                                     ...BANK_CODE.map(e=>({text:e.name, value:e.code})),
                                     {text: '기타', value: '000'}
                                   ]" @change="etcBankInit"></b-form-select>
              </b-input-group-prepend>
              <b-input v-model="item.bank" class="readonly" type="text"
                       :placeholder="`기타일 경우, 은행명을 입력해주세요.`" :readonly="item.bank_code !== '000'"></b-input>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <small>계좌번호</small><br/>
            <b-form-group>
              <b-input-group>
                <b-form-input v-model="item.account"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <small>예금주</small><br/>
            <b-form-input v-model="item.account_owner"></b-form-input>
          </b-col>
          <b-col cols="6">
            <small>세금계산서 발행 이메일</small><br/>
            <b-form-input v-model="item.tax_invoice_email"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>통장사본 확인</small> <br/>
            <div class="col-form-label" v-if="item.accFile && item.accFile.accName">
              <a v-if="PRIVATE" @click="$utils.open(`http://files.balaan.io/${item.accFile.s3Acc}`)" :title="item.accFile.accName" class="pointer">
                <i class="fa fa-file-o"></i>
                {{ item.accFile.accName }}
              </a>
              <span v-else>{{ item.accFile.accName }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>


    <div>
      <div v-if="item.manager">
        <div class="title-sm">담당자 정보</div>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>영업담당자명</small><br/>
            <b-input v-model="item.manager.md_name"></b-input>
          </b-col>
          <b-col cols="3">
            <small>영업담당자 연락처</small>
            <b-form-input v-model="item.manager.md_mobile" class="readonly" :readonly="item.manager.auth"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>영업담당자 이메일</small>
            <b-form-input v-model="item.manager.md_email"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>배송담당자명</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'반품택배 신청시 수취인에 들어갈 이름'"></i><br/>
            <b-input v-model="item.manager.deli_name"></b-input>
          </b-col>
          <b-col cols="3">
            <small>배송담당자 연락처</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'반품 전용 연락처를 SHOP에서 따로 요구할 경우 사용'"></i><br/>
            <b-form-input v-model="item.manager.deli_mobile"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>배송담당자 이메일</small><br/>
            <b-form-input v-model="item.manager.deli_email"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>정산담당자명</small><br/>
            <b-input v-model="item.manager.settle_name"></b-input>
          </b-col>
          <b-col cols="3">
            <small>정산담당자 연락처</small>
            <b-form-input v-model="item.manager.settle_mobile"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>정산담당자 이메일</small>
            <b-form-input v-model="item.manager.settle_email"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>CS담당자명</small><br/>
            <b-input v-model="item.manager.cs_name"></b-input>
          </b-col>
          <b-col cols="3">
            <small>CS담당자 연락처</small>
            <b-form-input v-model="item.manager.cs_mobile"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>CS담당자 이메일</small>
            <b-form-input v-model="item.manager.cs_email"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row class="mb-2">
          <b-col cols="6">
            <small>연락처</small><br/>
            <b-form-input v-model="item.contact"></b-form-input>
          </b-col>
          <b-col cols="3">
            <small>반품수취인</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'반품택배 신청시 수취인에 들어갈 이름'"></i><br/>
            <b-input v-model="item.return_receiver"></b-input>
          </b-col>
          <b-col cols="3">
            <small>반품연락처</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'반품 전용 연락처를 SHOP에서 따로 요구할 경우 사용'"></i><br/>
            <b-form-input v-model="item.return_contact"></b-form-input>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-if="item.service_center">
      <div class="title-sm">고객센터 정보</div>
      <b-row>
        <b-col cols="6">
          <small>연락처</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'파트너센터 이용 파트너의 경우 판매자 문의번호와 동일'"></i><br/>
          <b-form-group>
            <b-input-group>
              <b-form-select id="service_center_area_code" v-model="item.service_center.area_code" class="col-4"
                             :options="AREA_CODE.map(e => ({text: e.code, value: e.code}))"></b-form-select>
              <b-form-input v-model="item.service_center.phone" class="readonly" :readonly="item.service_center.auth"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <small>이메일</small>
          <b-form-input v-model="item.service_center.email"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>운영시간</small><br/>
          <b-form inline>
            <b-form-timepicker locale="kr" class="col-5 text-center" v-model="item.service_center.op_from"></b-form-timepicker>
            <div class="col-2 text-center">~</div>
            <b-form-timepicker locale="kr" class="col-5 text-center" v-model="item.service_center.op_to"></b-form-timepicker>
          </b-form>
        </b-col>
        <b-col cols="6">
          <small>점심시간</small><br/>
          <b-form inline>
            <b-form-timepicker locale="kr" class="col-5 text-center" v-model="item.service_center.lunch_from"></b-form-timepicker>
            <div class="col-2 text-center">~</div>
            <b-form-timepicker locale="kr" class="col-5 text-center" v-model="item.service_center.lunch_to"></b-form-timepicker>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="6">
          <small>상담 제외 시간</small><br/>
          <b-form inline>
            <b-form-checkbox-group name="serviceDays" v-model="item.service_center.no_service_day">
              <b-form-checkbox v-for="s in [
                  {name: '점심시간', code: 'lunch'},
                  {name: '토요일', code: 'saturday'},
                  {name: '일요일', code: 'sunday'},
                  {name: '공휴일', code: 'holiday'}
                ]" :key="s.code" :value="s.code">
                {{s.name}}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <div class="title-sm">파트너 정보(외부몰 용도)</div>
    <b-img-lazy class="mw-100 mb-3" v-if="item.img && item.img.startsWith('http')" :src="item.img"></b-img-lazy>
    <b-row class="mt-2">
      <b-col cols="2">
        <small>파트너 이름 노출</small><br/>
        <c-switch v-model="item.name_open" class="mt-2" color="success" label variant="pill" />
      </b-col>
      <b-col>
        <small>파트너 이름</small><br/>
        <b-form-input v-model="item.name"></b-form-input>
      </b-col>
      <b-col>
        <small>파트너 이미지 URL</small><br/>
        <b-form-input v-model="item.img"></b-form-input>
      </b-col>
    </b-row>
    <div class="mt-2">
      <small>파트너 개요</small><br/>
      <b-textarea rows="5" v-model="item.explain"></b-textarea>
    </div>

  </div>
</template>

<script>
import {Switch as cSwitch} from '@coreui/vue';
import {AREA_CODE, BANK_CODE} from 'balaan_constants';

export default {
  name: 'ShopPartner',
  components: {cSwitch},
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
  },
  data() {
    return {
      AREA_CODE, BANK_CODE,
      PARTNER_SETTLE_TYPE: this.$C.PARTNER_SETTLE_TYPE.filter(e=>e.code && !['b_type', 'd_type'].includes(e.code)).map(e=>({text: e.name, value: e.code}))
    }
  },
  created() {
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    setAddressType(type) {
      this.item.addressType = type;
      this.$forceUpdate();
    },
    setReturnAddressType(type) {
      this.item.returnAddressType = type;
      this.$forceUpdate();
    },
    updateAddress(type) {
      if (type === 'return') {
        if (this.item.returnAddressType === 'global') {
          this.item.return_address = (this.item.addr.returnAddressDetail || '') + ', ' + (this.item.addr.returnAddress || '');
        } else {
          this.item.return_address = (this.item.addr.returnAddress ? this.item.addr.returnAddress + ' ' : '') + (this.item.addr.returnAddressDetail || '');
        }
      } else {
        if (this.item.addressType === 'global') {
          this.item.address = (this.item.addr.addressDetail || '') + ', ' + (this.item.addr.address || '');
        } else {
          this.item.address = (this.item.addr.address ? this.item.addr.address + ' ' : '') + (this.item.addr.addressDetail || '');
        }
      }
      this.$forceUpdate();
    },
    async openAddressPopup(type) {
      // goodsflow 연동 보류
      // if (type === 'return' && this.item._partner) {
      //   return alert('파트너센터에 가입된 파트너의 반품주소는 GoodsFlow 의 연동 때문에, 파트너센터에서 수정해주세요.');
      // }
      const $this = this;
      new daum.Postcode({
        oncomplete(data) {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
          // 예제를 참고하여 다양한 활용법을 확인해 보세요.

          console.log(data);

          // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let extraRoadAddr = ''; // 참고 항목 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')';
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          if (type === 'return') {
            $this.item.addr.returnPostcode = data.zonecode;
            $this.item.addr.returnAddress = data.roadAddress + extraRoadAddr;
            $this.item.addr.returnAddressEnglish = data.roadAddressEnglish;
            $this.$refs.returnAddressDetail.select();
          } else {
            $this.item.addr.postcode = data.zonecode;
            $this.item.addr.address = data.roadAddress + extraRoadAddr;
            $this.item.addr.addressEnglish = data.roadAddressEnglish;
            $this.$refs.addressDetail.select();
          }
          $this.updateAddress(type);
        }
      }).open();
    },
    etcBankInit() {
      if (this.item.bank_code !== '000') {
        this.item.bank = '';
      }
    },
    checkSettlemYNChange(value) {
      if (!confirm(`해당 파트너는 파트너관리 Shop입니다. 정산 여부를 변경하시겠습니까? 정산 유무 변경 시 아직 정산되지 않은 정산 대상 주문 건은 변경된 기준으로 적용됩니다.`)) {
        this.item.settle_yn = value === 'y' ? 'n' : 'y';
      }
    }
  }
}
</script>

<style>
.shop-settle-tooltip > .tooltip-inner {
  text-align: left;
  max-width: none !important;
}
</style>
